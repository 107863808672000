




















































































































import { Vue, Component, Watch } from 'vue-class-decorator'
import { GlobalModule, UserModule } from '@/store'
import { Payload } from 'vue/interfaces'

@Component({
  metaInfo: {
    title: 'Группы и права'
  }
})
export default class UsersGroups extends Vue {
  public tableGroups: Array<object>
  public groupDialog: boolean
  public groupDialogTitle: string
  public dialogKey: boolean
  public selectedGroup: { [propName: string]: any }

  // Data
  constructor() {
    super()
    this.dialogKey = false
    this.groupDialog = false
    this.selectedGroup = {}
    this.groupDialogTitle = 'Создание'
    this.tableGroups = [
      {
        text: 'Название группы',
        value: 'title',
        align: 'start',
        sortable: false
      },
      {
        text: 'Опции',
        value: 'user_id',
        sortable: false
      }
    ]
  }

  // Store init
  @GlobalModule.Getter('getLoading') loading!: boolean
  @UserModule.Getter('getUserGroups') groups!: Array<object>
  @UserModule.Action('requestUserGroups') loadUserGroups!: () => Promise<void>
  @UserModule.Action('requestUserGroupDelete') deleteGroup!: (id: number) => Promise<void>
  @UserModule.Action('saveUserGroup') pushGroup!: (user: Payload) => Promise<void>

  // Watch
  @Watch('groupDialog') clearModalFields() {
    if (!this.groupDialog) {
      this.clearFields()
      setTimeout(() => {
        this.dialogKey = !this.dialogKey
      }, 200)
    }
  }

  // Methods
  loadUserGroupsList() {
    this.loadUserGroups()
      .catch((error: any) => {
        this.$noty('error', error.message, 7000).show()
      })
  }

  deleteGroupProcess(id: number) {
    this.deleteGroup(id)
      .then(() => {
        this.$noty('success', 'Группа удалена!', 5000).show()
        this.loadUserGroupsList()
      })
      .catch((error: any) => {
        this.$noty('error', error.message, 7000).show()
      })
  }

  viewGroup(id: number) {
    const group = this.groups.find((group: any) => group.group_id === id)
    this.selectedGroup = group as object
    this.groupDialogTitle = 'Редактирование'
    this.groupDialog = true
  }

  clearFields() {
    this.groupDialogTitle = 'Создание'
    this.selectedGroup = {}
  }

  saveGroup() {
    this.pushGroup(this.selectedGroup as Payload)
      .then(() => {
        this.$noty('success', 'Группа сохранена!', 5000).show()
        this.groupDialog = false
        this.loadUserGroupsList()
      })
      .catch((error: any) => {
        this.$noty('error', error.message, 7000).show()
      })
  }

  // Hooks
  created() {
    this.loadUserGroupsList()
  }
}
