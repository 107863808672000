var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('h1',[_vm._v("Группы и права")]),_c('v-btn',{attrs:{"depressed":"","color":"primary","loading":_vm.loading},on:{"click":function($event){_vm.groupDialog = true}}},[_vm._v(" Добавить ")])],1),_c('section',{staticClass:"mt-5"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.tableGroups,"items":_vm.groups,"items-per-page":10,"loading":_vm.loading,"loading-text":"Загрузка...","no-data-text":"Похоже тут ничего нет","footer-props":{
        itemsPerPageText: 'Элементов в таблице',
        itemsPerPageAllText: 'Все',
        itemsPerPageOptions: [5, 10, 15, -1]
      }},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.title))])]}},{key:"item.user_id",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"actions"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.viewGroup(item.group_id)}}},[_c('v-icon',{staticClass:"default",attrs:{"title":"Просмотр"}},[_vm._v(" mdi-pencil-outline ")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteGroupProcess(item.group_id)}}},[_c('v-icon',{staticClass:"red-option",attrs:{"title":"Удалить"}},[_vm._v(" mdi-delete-outline ")])],1)],1)]}},{key:"footer.page-text",fn:function(items){return [_vm._v(" "+_vm._s(items.pageStart)+" - "+_vm._s(items.pageStop)+" из "+_vm._s(items.itemsLength)+" ")]}}],null,true)})],1),_c('aside',[_c('v-dialog',{key:_vm.dialogKey,attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.groupDialog),callback:function ($$v) {_vm.groupDialog=$$v},expression:"groupDialog"}},[_c('v-card',[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.saveGroup()}}},[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.groupDialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.groupDialogTitle))]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"dark":"","text":"","type":"submit"}},[_vm._v(" Сохранить ")])],1)],1),_c('v-list',{staticClass:"container mt-5",attrs:{"three-line":"","subheader":""}},[_c('v-text-field',{ref:"title",attrs:{"rules":[function () { return !!_vm.selectedGroup.title || 'Введите название группы'; }],"label":"Название","placeholder":"","required":""},model:{value:(_vm.selectedGroup.title),callback:function ($$v) {_vm.$set(_vm.selectedGroup, "title", $$v)},expression:"selectedGroup.title"}})],1)],1)])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }